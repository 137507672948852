import React from "react"
import { Link } from "gatsby"

import Image from "../../images/business.jpg"

const Hero = () => {
  return (
    <div className="hero">
      <div
        className="bg-image"
        style={{ backgroundImage: `url(${Image})` }}
      ></div>

      <div className="bg-text">
        <h3 className="second-heading">Make smart decision</h3>
        <h1 className="main-heading">We Help You Buy Smart!</h1>
        <p className="caption">
          Making important purchases for your business is a tough task. You
          could call individual companies and fight your way through a maze of
          recordings, but that takes time and time is money. Let Shop Biz
          Services quickly find the right seller for your needs who will compete
          to win your business.
        </p>
        <Link className="" to="/all-categories">
          <button className="btn btn-primary">Find out how</button>
        </Link>
      </div>
    </div>
  )
  // <div className="banner">
  //   <Row className="m-0 h-100 home-hero-container">
  //     <Col
  //       md={7}
  //       className="h-100 content-container d-flex flex-column justify-content-center px-2 px-md-5 text-center text-light  text-md-left"
  //     >
  //       <h1 className="banner-title line-height-4 letter-spacing-2">
  //         We Help You Buy Smart!
  //       </h1>

  //       <h2 className="banner-title line-height-4 letter-spacing-2">
  //         Make smart decision
  //       </h2>
  //       <p>
  //         Making important purchases for your business is a tough task. You
  //         could call individual companies and fight your way through a maze of
  //         recordings, but that takes time and time is money. Let Shop Biz
  //         Services quickly find the right seller for your needs who will
  //         compete to win your business.
  //       </p>
  // <div className="inline mt-3">
  //   <Link className="" to="/all-categories">
  //     <button className="btn btn-primary">Find out more</button>
  //   </Link>
  // </div>
  //     </Col>
  //     <Col md={5} className="d-md-flex align-items-center d-none">
  //       <div className="banner-image-wrapper">
  //         <Image src="hero2.png" />
  //       </div>
  //     </Col>
  //   </Row>
  // </div>
}

export default Hero
