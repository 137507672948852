import React from "react"
import Image from "../image"

const CallToAction = () => {
  return (
    <React.Fragment>
      <div className="container-fluid call-to-action-main py-3 mt-3">
        <div className="call-to-action-content">
          <p className="call-to-action-heading mb-0">
            We’ve got your home needs covered, too.
          </p>
          <p className="call-to-action-content-sub">
            Compare quotes for Manufactured Homes, Security Systems and more
          </p>
        </div>
      </div>

      <div className="container call-to-action-subsection d-flex flex-column py-3">
        <div className="row mb-5 mt-3">
          <div className="col-xs-12 col-md-6 d-flex justify-content-center flex-column">
            <h6 className="">
              We’ve helped millions of businesses save time and money.
            </h6>
            <p className="">
              Making important purchases for your business is a tough task. You
              could call individual companies and fight your way through a maze
              of recordings, but that takes time and time is money. Let
              360Connect quickly find the right seller for your needs who will
              compete to win your business.
            </p>
          </div>
          <div className="col-xs-12 col-md-6">
            <Image
              className="cal-to-action-img-1"
              src="img-about-us-1.png"
            ></Image>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-xs-12 col-md-6 order-2 order-md-1">
            <Image
              className="cal-to-action-img-2"
              src="img-about-us-2.png"
            ></Image>
          </div>
          <div className="col-xs-12 col-md-6 d-flex justify-content-center flex-column order-1 order-md-2">
            <h6 className="">Looking to fill your sales pipeline?</h6>
            <p className="">
              We’re always looking for quality sellers. Our lead generation
              programs have helped thousands of clients close more business.
              Connect with pre-screened, in-market buyers today.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CallToAction
