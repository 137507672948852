import React from "react"
import Image from "../image"
import Card from "./ourServicesComponents/cards"

const OurServices = () => {
  const servicesList = [
    [
      {
        link: "/merchant-cash-advance",
        name: "Merchant Cash Advance",
      },
      {
        link: "/business-internet-service",
        name: "Business Internet Service",
      },

      {
        link: "/collection-agencies",
        name: "Collection Agencies",
      },
      {
        link: "/teleconferencing-service",
        name: "Teleconferencing Service",
      },
    ],
    [
      {
        link: "/modular-or-manufactured-homes",
        name: "Modular or Manufactured Homes",
      },
      {
        link: "/building-steps-and-ramps",
        name: "Building Steps and Ramps",
      },
      {
        link: "/storage-containers",
        name: "Monitored Alarm Systems",
      },
    ],
    [
      {
        link: "/aerial-work-plateforms",
        name: "Aerial Work Plateforms",
      },
      {
        link: "/mobile-offices",
        name: "Mobile Offices",
      },
      {
        link: "/steel-buildings",
        name: "Steel Building",
      },

      {
        link: "/modular-buildings",
        name: "Modular Buildings",
      },
    ],
    [
      {
        link: "/application-tracking-software",
        name: "Application Tracking Software",
      },
      {
        link: "/call-center-software",
        name: "Call Center Software",
      },
      {
        link: "/customer-relationship-management-software",
        name: "CRM software",
      },

      {
        link: "/enterprise-resource-planning-software",
        name: "ERP Software",
      },
    ],
  ]
  return (
    <div className="container our-services">
      <div className="row d-flex justify-content-center align-items-center flex-column my-4">
        <div className="col d-flex justify-content-center">
          <Image className="icon w-100" src="our-services-img.png" />
        </div>
        <div className="col d-flex justify-content-center">
          <p className="main-heading">Our Services</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-6">
          <Card
            title="Professional"
            img="pro__logo.png"
            col__color="#003049"
            content={servicesList[0]}
          />
        </div>
        <div className="col-xs-12 col-lg-6">
          <Card
            title="Home"
            img="home__logo.png"
            col__color="#D62828"
            content={servicesList[1]}
          />
        </div>
        <div className="col-xs-12 col-lg-6">
          <Card
            title="Construction"
            img="constructin__logo.png"
            col__color="#F77F00"
            content={servicesList[2]}
          />
        </div>
        <div className="col-xs-12 col-lg-6">
          <Card
            title="Digital Services"
            img="health__logo.png"
            col__color="#FCBF49"
            content={servicesList[3]}
          />
        </div>
      </div>
    </div>
  )
}

export default OurServices
