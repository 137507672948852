import React from "react"

import Card from "./howItWorksComponents/cards"
import logo from "../../images/how-it-works-img.svg"
import logo1 from "../../images/col-3-icon.svg"
import logo2 from "../../images/col-1-icon.svg"
import logo3 from "../../images/col-3-icon (2).svg"

const HowItWorks = () => {
  return (
    <div className="how-it-works-main pb-5">
      <div className="container-fluid d-flex justify-content-center align-items-center flex-column">
        <div className="row d-flex justify-content-center align-items-center flex-column my-4">
          <div className="col d-flex justify-content-center">
            <img className="main-logo" alt="main-logo" src={logo} />
          </div>
          <div className="col d-flex justify-content-center">
            <p className="main-heading">How it Works?</p>
          </div>
        </div>
        <div className="container row">
          <div className="col-xs-12 col-lg-4 cards-column">
            <Card
              title="Share Needs"
              img={logo1}
              content="Tell us just a few basic details about what you’re looking to buy. 
            Our quick forms will guide you through the key questions to consider."
            />
          </div>
          <div className="col-xs-12 col-lg-4 cards-column">
            <Card
              title="Get Connected"
              img={logo2}
              content="We’ll introduce you to a few, hand-selected sellers who will provide price 
            quotes and answer any questions you may have."
            />
          </div>
          <div className="col-xs-12 col-lg-4 cards-column">
            <Card
              title="Buy Smart"
              img={logo3}
              content="You’ll compare multiple price quotes so you know you’re getting the best deal. 
            Plus, our handy Buyer’s Guides will prep you for the ins and outs."
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
